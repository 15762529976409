import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import "../styles/NavBarMenu.css";
import {
  ConnectWithoutContact,
  Dashboard,
  GridView,
  Settings,
} from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import TerminalIcon from '@mui/icons-material/Terminal';
import { useNavigate } from "react-router-dom";
type Anchor = "top" | "left" | "bottom" | "right";

interface NavBarMenuProps {
  isHamburgerOpen: boolean;
  setIsHamburgerOpen: (isHamburgerOpen: boolean) => void;
}

const NavBarMenu = (props: NavBarMenuProps) => {
  const navigate = useNavigate();
  const handleClose =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        props.setIsHamburgerOpen(false);
      };

  const handleLinkClick = (slug: string) => {
    navigate(slug);
  }

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={handleClose(anchor, false)}
      onKeyDown={handleClose(anchor, false)}
    >
      <List>
        <ListItem key={"Home"} disablePadding>
          <ListItemButton href="/">
            <ListItemIcon>
              <Dashboard></Dashboard>
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Experience"} disablePadding>
          <ListItemButton onClick={() => handleLinkClick("experience")}>
            <ListItemIcon>
              <GridView></GridView>
            </ListItemIcon>
            <ListItemText primary={"Experience"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"My Skills"} disablePadding>
          <ListItemButton onClick={() => handleLinkClick("skills")}>
            <ListItemIcon>
              <TerminalIcon></TerminalIcon>
            </ListItemIcon>
            <ListItemText primary={"My Skills"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Personal Profile"} disablePadding>
          <ListItemButton onClick={() => handleLinkClick("profile")}>
            <ListItemIcon>
              <PersonIcon></PersonIcon>
            </ListItemIcon>
            <ListItemText primary={"Personal Profile"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Contact"} disablePadding >
          <ListItemButton onClick={() => handleLinkClick("contact")}>
            <ListItemIcon>
              <ConnectWithoutContact></ConnectWithoutContact>
            </ListItemIcon>
            <ListItemText primary={"Contact"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key={"Settings"} disablePadding>
          <ListItemButton disabled href="/settings">
            <ListItemIcon>
              <Settings></Settings>
            </ListItemIcon>
            <ListItemText primary={"Settings (coming soon)"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor={"right"}
      open={props.isHamburgerOpen}
      onClose={handleClose("right", false)}
    >
      {list("right")}
    </Drawer>
  );
};
export default NavBarMenu;
