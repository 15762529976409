import { AppBar, Box, IconButton } from "@mui/material";
import "../styles/NavBar.css";
// import logo from "../assets/white-ghost-trans.png";
// import logoText from "../assets/white-text-trans.png";
import MenuIcon from "@mui/icons-material/Menu";

interface Props {
  setIsHamburgerOpen: (isHamburgerOpen: boolean) => void;
}

export default function NavBar(props: Props) {
  const handleOpen = () => {
    props.setIsHamburgerOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "7vh",
      }}
    >
      <AppBar
        sx={{
          borderBottom: 1,
          backgroundColor: "rgba(0,0,0,0.8)",
        }}
        position="static"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginY: "0.5rem",
            paddingX: "3rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 50,
            }}
          >
            {/* Commenting out because of rework due to repurposing this site for a portfolio. 
            <img src={logo} className="logo" />
            <img src={logoText} className="logo-text" /> */}
            <a href="/" style={{textDecoration: "none", color:"white"}}>
              <h1 className="logo-text">JOELDANIELZ</h1>
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="large"
              onClick={() => {
                handleOpen();
              }}
              color={"secondary"}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
}
