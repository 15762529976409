import { Box } from "@mui/system";
import "../styles/SplashScreen.css";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Typography } from "@mui/material";

interface SplashScreenProps {
  setSplashScreenShown: (isHamburgerOpen: boolean) => void;
}

const SplashScreen = (props: SplashScreenProps) => {
  const closeSplashScreen = () => {
    localStorage.setItem("splashShown", "true");
    props.setSplashScreenShown(false);
  };

  return (
    <Box
      className="splash-screen"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 50,
      }}
    >
      <Typography
        className="splash-screen-text"
        component="h6"
        variant="h6"
        align="center"
        color="white"
        onClick={() => {
          closeSplashScreen();
        }}
      >
        Start your journey with me now
        <DoubleArrowIcon sx={{ paddingTop: "10px" }} />
      </Typography>
    </Box>
  );
};

export default SplashScreen;
