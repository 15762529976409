import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  CssBaseline,
  GlobalStyles,
  Grid,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import "../styles/Home.css";
import React from "react";

const Pricing = () => {
  const tiers = [
    {
      title: "Creative Website Design",
      price: "300",
      description: [
        "Some information here.",
        "Some information here.",
        "Some information here.",
        "Some information here.",
      ],
      buttonText: "Sign up for free",
      buttonVariant: "outlined",
    },
    {
      title: "Bespoke Software",
      subheader: "Most popular",
      price: "1999",
      description: [
        "Some information here.",
        "Some information here.",
        "Some information here.",
        "Some information here.",
      ],
      buttonText: "Get started",
      buttonVariant: "contained",
    },
    {
      title: "Integration & Automation",
      price: "999",
      description: [
        "Some information here.",
        "Some information here.",
        "Some information here.",
        "Some information here.",
      ],
      buttonText: "Contact us",
      buttonVariant: "outlined",
    },
  ];

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing & Plans
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          component="p"
        >
          Some information here.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => {
            return (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          marginRight: "8px",
                        }}
                        variant="body1"
                        color="text.secondary"
                      >
                        From
                      </Typography>
                      <Typography
                        component="h2"
                        variant="h4"
                        color="text.primary"
                      >
                        ${tier.price}
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={tier.buttonVariant as "outlined" | "contained"}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Pricing;
