import React from 'react';
import { Box, Typography, Card, Divider } from "@mui/material";
import bg from '../assets/backgroundPorto.jpg';

const PersonalProfile: React.FC = () => (
  <Box sx={{ position: "relative", width: "100vw", minHeight: "100vh" }}>
    {/* Background Layer */}
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        zIndex: -1,
      }}
    />

    {/* Scrollable Content */}
    <Box className="section"
      sx={{
        position: "relative",
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingY: { xs: "15%", md: "3%" },
          paddingX: { xs: "5%", md: "4%" },
          textAlign: "center",
          width: { xs: "90vw", md: "60vw" },
          color: '#FFFFFF',
          borderRadius: "12px",
        }}
        color="primary"
      >
        <Typography color="secondary" variant="h4" sx={{ fontWeight: "bold" }}>
          Personal Profile
        </Typography>
        <Divider sx={{ my: "1.5rem", width: { xs: "80%", md: "40rem" } }} />

        {/* Personal Overview */}
        <Box sx={{ textAlign: "left", width: "100%", paddingX: { xs: 0, md: "1rem" } }}>
          <Typography variant="body1" color="text.primary" sx={{ marginBottom: "1.5rem" }}>
            I am a passionate software engineer with over 7 years of professional experience, rooted in a lifelong love of technology. Inspired by my father, a developer himself, I began my coding journey in HTML/CSS at a young age, progressively mastering JavaScript, Python, Java, and C#. My dedication to coding drives me to continually expand my skill set and embrace new challenges.
          </Typography>

          {/* Professional Journey */}
          <Typography variant="h6" color="secondary" sx={{ fontWeight: 'bold', pb: "0.5rem" }}>
            Professional Journey
          </Typography>
          <Typography variant="body1" color="text.primary" sx={{ marginBottom: "1.5rem" }}>
            My career began at PulseGuard, where I developed APIs for a life-saving medical monitoring system. At Happen Web Technologies, I advanced my full-stack skills, focusing on .NET and modern JavaScript frameworks like Angular. Currently, I’m a Full Stack Developer at Three Rocks, where I lead complex projects, including secure microservices, robust API integrations, and responsive front-end applications with React and Tailwind. I take pride in crafting software solutions that are both powerful and user-friendly, collaborating with cross-functional teams, and delivering quality work for clients like Pizza Hut Restaurants.
          </Typography>

          {/* Interests & Hobbies */}
          <Typography variant="h6" color="secondary" sx={{ fontWeight: 'bold', pb: "0.5rem" }}>
            Interests & Hobbies
          </Typography>
          <Typography variant="body1" color="text.primary" sx={{ marginBottom: "1.5rem" }}>
            Outside of development, I’m passionate about Mixed Martial Arts (MMA), which has instilled in me a strong sense of discipline, focus, and resilience. Gaming is another passion, one that continuously inspires my creativity. I’m also deeply interested in artificial intelligence, fascinated by its potential to revolutionize software development and transform everyday life.
          </Typography>

          {/* Technical Skills */}
          <Typography variant="h6" color="secondary" sx={{ fontWeight: 'bold', pb: "0.5rem" }}>
            Technical Skills & Expertise
          </Typography>
          <Typography variant="body1" color="text.primary" sx={{ marginBottom: "1.5rem" }}>
            I have extensive expertise in C#, .NET (versions 8, 7, and 6), .NET Core, and the .NET Framework, with deep experience in creating efficient, scalable APIs and secure microservices architectures. My front-end skills encompass React, Angular, Vue, and Nuxt, with strong experience in responsive design using Tailwind CSS and Material UI. My backend proficiency extends to SQL and MySQL (with Entity Framework and Dapper), and I manage version control and CI/CD through Git, TFS, and Azure DevOps. My goal is to build maintainable, high-quality software that enhances user experience and meets strategic goals.
          </Typography>

          {/* Contact Info */}
          <Typography variant="body2" color="text.primary" sx={{ textAlign: "center", paddingTop: "1.5rem" }}>
            Interested in discussing a project or potential collaboration? Feel free to <a href="/contact" style={{ color: "#90caf9" }}>reach out</a>.
          </Typography>
        </Box>
      </Card>
    </Box>
  </Box>
);

export default PersonalProfile;
