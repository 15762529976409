import React from 'react';
import { Box, Typography, Card, Divider, Tooltip } from "@mui/material";
import bg from '../assets/backgroundLondon.jpg';

// Type for a Skill
interface Skill {
    logo: string;
    name: string;
    tooltip?: string; // Detailed tooltip description
    style?: React.CSSProperties;
}

interface SkillSectionProps {
    title: string;
    skills: Skill[];
    description?: string;
}

const MySkills: React.FC = () => (
    <Box sx={{ position: "relative", width: "100vw", minHeight: "100vh" }}>
        {/* Background Layer */}
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                zIndex: -1,
            }}
        />

        {/* Scrollable Content */}
        <Box className="section"
            sx={{
                position: "relative",
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingY: { xs: "15%", md: "3%" },
                    paddingX: { xs: "10%", md: "4%" },
                    textAlign: "center",
                    width: { xs: "90vw", md: "60vw" },
                    color: '#FFFFFF',
                    borderRadius: "12px",
                }}
            >
                <Typography color="secondary" variant="h4" sx={{ fontWeight: "bold" }}>
                    My Skills
                </Typography>
                <Divider sx={{ my: "1.5rem", width: { xs: "80%", md: "40rem" } }} />

                {/* Skill Sections */}
                <SkillSection 
                    title="Backend Development" 
                    skills={[
                        { logo: "https://cdn.worldvectorlogo.com/logos/c--4.svg", name: "C#, .NET", tooltip: "C# and .NET Core are powerful, versatile frameworks for backend development. .NET 6, 7, and 8 offer the latest enhancements in performance and security." }
                    ]}
                />

                <SkillSection 
                    title="Frontend Development" 
                    skills={[
                        { logo: "https://cdn.worldvectorlogo.com/logos/react-2.svg", name: "React", tooltip: "React is a popular library for building interactive UIs, known for its component-based structure and efficient rendering." },
                        { logo: "https://cdn.worldvectorlogo.com/logos/angular-icon-1.svg", name: "Angular", tooltip: "Angular is a robust front-end framework by Google for building scalable, enterprise-level applications with MVC architecture." },
                        { logo: "https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg", name: "Vue", tooltip: "Vue.js is a progressive JavaScript framework for building user interfaces, known for its simplicity and flexibility." },
                        { logo: "https://cdn.worldvectorlogo.com/logos/javascript-1.svg", name: "JavaScript", tooltip: "JavaScript is essential for web development, allowing dynamic, interactive content across front-end applications." },
                        { logo: "https://cdn.worldvectorlogo.com/logos/tailwind-css-2.svg", name: "Tailwind CSS", tooltip: "Tailwind CSS is a utility-first CSS framework for creating responsive, custom-designed UIs directly in your markup." },
                        { logo: "https://cdn.worldvectorlogo.com/logos/material-ui-1.svg", name: "Material UI", tooltip: "Material UI provides React components that implement Google’s Material Design, allowing consistent, accessible UIs." }
                    ]}
                    description="React, Angular, Vue, Nuxt, and other JavaScript frameworks/libraries"
                />

                <SkillSection 
                    title="Version Control" 
                    skills={[
                        { logo: "https://cdn.worldvectorlogo.com/logos/git-icon.svg", name: "Git", tooltip: "Git is a distributed version control system essential for collaboration and tracking code changes in software development." },
                        { logo: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Logo_TFS.svg", name: "TFS", style: { filter: "invert(1)" }, tooltip: "Team Foundation Server (TFS) by Microsoft provides source control, project management, and collaboration for development teams." }
                    ]}
                    description="GIT & TFS (GIT Bash, Git Kraken)"
                />

                <SkillSection 
                    title="Mobile Development" 
                    skills={[
                        { logo: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg", name: "iOS", style: { filter: "invert(1)" }, tooltip: "iOS development focuses on creating applications for Apple devices, leveraging tools like Xamarin and React Native for cross-platform support." },
                        { logo: "https://cdn.worldvectorlogo.com/logos/android-logomark.svg", name: "Android", tooltip: "Android development involves creating applications for Android devices, often using Java or Kotlin." }
                    ]}
                    description="iOS & Android Development (Xamarin, Ionic, React Native)"
                />

                <SkillSection 
                    title="Additional Skills" 
                    skills={[
                        { logo: "https://www.svgrepo.com/download/331760/sql-database-generic.svg", name: "SQL", tooltip: "SQL (Structured Query Language) is used for managing and querying relational databases, essential for backend data operations." },
                        { logo: "https://www.vectorlogo.zone/logos/mysql/mysql-ar21.svg", name: "MySQL", tooltip: "MySQL is a popular open-source relational database system for efficient data storage and retrieval in web applications." },
                        { logo: "https://cdn.worldvectorlogo.com/logos/microsoft-azure-3.svg", name: "Azure", tooltip: "Azure is Microsoft’s cloud platform, offering services for app hosting, storage, and DevOps." }
                    ]}
                    description="SQL & MySQL (Entity Framework, Dapper)"
                />
            </Card>
        </Box>
    </Box>
);

// SkillSection Component
const SkillSection: React.FC<SkillSectionProps> = ({ title, skills, description }) => (
    <Box sx={{ paddingTop: "1rem", textAlign: "left", width: "100%" }}>
        <Typography variant="h6" color="secondary" sx={{ fontWeight: 'bold', pb: "0.5rem" }}>
            {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "1.25rem", flexWrap: "wrap", gap: "15px" }}>
            {skills.map((skill, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Tooltip title={skill.tooltip || ""} arrow>
                        <Box
                            component="img"
                            src={skill.logo}
                            alt={skill.name}
                            sx={{
                                height: "30px",
                                transition: "transform 0.2s",
                                "&:hover": {
                                    transform: "scale(1.1)"
                                },
                                ...skill.style
                            }}
                        />
                    </Tooltip>
                    <Typography variant="body1" color="text.primary">{skill.name}</Typography>
                </Box>
            ))}
        </Box>
        {description && (
            <Typography variant="body2" color="text.primary" sx={{ paddingLeft: "1.25rem", paddingTop: "0.5rem" }}>
                {description}
            </Typography>
        )}
    </Box>
);

export default MySkills;
