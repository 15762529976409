import { Button, Card, Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "../styles/TextButton.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useNavigate } from "react-router-dom";

const HomePageCardLink = (Props: { title: string, body: string, url: string }) => {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    navigate(Props.url);
  }

  return (
    <Card
      sx={{
        padding: 1,
        paddingTop: 2,
        width: { xs: "90%", md: "36.5rem" }, // Set width to 90% on mobile and original size on desktop
        margin: { xs: "1rem auto", md: 2 }, // Center the card on mobile and keep original margin on desktop
        boxSizing: "border-box", // Ensures padding does not cause overflow
      }}
      color="primary"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          padding: "1rem",
          paddingTop: 0,
        }}
      >
        <Typography color="secondary" variant="h5" sx={{ paddingY: '0.2rem' }}>
          {Props.title}
        </Typography>
        <Divider sx={{ paddingTop: "1rem", width: "100%" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            color="secondary"
            variant="body1"
            sx={{
              paddingTop: "0.8rem",
              paddingBottom: "0.2rem",
            }}
          >
            {Props.body}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "0.3rem"
            }}
          >
            <IconButton onClick={handleLinkClick}>
              <Typography>GO</Typography>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default HomePageCardLink;
