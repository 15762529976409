import { Box, Card, Divider, Typography } from "@mui/material";
import Slogans from "../components/Slogans";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import HomePageCardLink from "../components/HomePageCardLink";
import { Link } from 'react-scroll';

const Home = () => (
  <Box sx={{ marginTop: { xs: "8%", md: "12%" }, marginBottom: { xs: "5%", md: "8%" } }}>
    <Slogans />
    <ScrollDownLink />
    <div id="explore"></div>
    <ExploreCard />
    <LinksSection />
  </Box>
);

const ScrollDownLink = () => (
  <Link to="explore" smooth={true}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        mt: { xs: "auto", md: "13%" }, // Positions button at the bottom on mobile
        minHeight: { xs: "70vh", md: "auto" }, // Ensures it stays near the bottom on mobile
        cursor: "pointer",
      }}
    >
      <Typography variant="caption" sx={{ marginBottom: "0.5rem" }}>
        See more
      </Typography>
      <KeyboardDoubleArrowDownIcon className="down-arrow-pulse" />
    </Box>
  </Link>
);

const ExploreCard = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginX: { xs: "5%", md: "10%" },
      marginTop: { xs: "5%", md: "10%" },
      textAlign: "center",
    }}
  >
    <Card
      sx={{
        padding: { xs: 2, md: 1 },
        paddingTop: { xs: 2, md: 2 },
        width: { xs: "100%", md: "75rem" },
        margin: { xs: 0, md: 0 },
        marginTop: { xs: 5, md: 10 },
      }}
      color="primary"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: { xs: "0.5rem", md: "0.8rem" },
          paddingTop: 0,
        }}
      >
        <Typography color="secondary" variant="h3" sx={{ paddingY: '0.2rem' }}>
          EXPLORE
        </Typography>
        <Divider sx={{ paddingTop: { xs: "0.5rem", md: "1rem" }, width: { xs: "90%", md: "75rem" } }} />
        <Typography
          color="secondary"
          variant="body1"
          sx={{ paddingTop: "0.8rem", paddingX: { xs: "1rem", md: "5rem" }, paddingBottom: "0.5rem" }}
        >
          Take a moment to explore and get to know my work. Here, you’ll find an overview of my professional experience, showcasing the projects I've completed and the expertise I've developed throughout my career. You can also visit the 'My Skills' section for an in-depth look at the technical knowledge, tools, and technologies I bring to each project. Additionally, my personal profile shares insights into my interests and values, giving you a sense of my approach to software development. If you’re interested in discussing new opportunities or potential collaborations, please feel free to reach out through the contact page. Thank you for visiting!
        </Typography>
      </Box>
    </Card>
  </Box>
);

const LinksSection = () => (
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: { xs: 2, md: 0 }, marginTop: { xs: "2rem", md: "2rem" } }}>
    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" }, gap: { xs: 2, md: 2.5 }, marginY: { xs: 1, md: 0 } }}>
      <HomePageCardLink title="EXPERIENCE" body="Explore the projects I’ve worked on and the expertise I’ve developed throughout my career." url="experience" />
      <HomePageCardLink title="MY SKILLS" body="Explore the projects I’ve worked on and the expertise I’ve developed throughout my career." url="skills" />
    </Box>
    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" }, gap: { xs: 2, md: 2.5 }, marginY: { xs: 1, md: 0 } }}>
      <HomePageCardLink title="PERSONAL PROFILE" body="Explore my personal insights and values." url="profile" />
      <HomePageCardLink title="CONTACT ME" body="Reach out for collaboration or opportunities." url="contact" />
    </Box>
  </Box>
);

export default Home;
