import React, { useState } from 'react';
import { Box, Typography, FormControl, TextField, Card, Divider, Button } from "@mui/material";
import bg from '../assets/programmer-at-work-stockcake.jpg';
import emailjs from 'emailjs-com';

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState({
    from_first_name: '',
    from_last_name: '',
    company: '',
    phone: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  // Handles form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Sends the email via EmailJS
  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('Sending...');

    emailjs.send(
      'service_b9fu6zi',         // Service ID
      'template_5jf0joo',        // Template ID
      {
        to_name: 'joeldanielz@icloud.com', 
        from_first_name: formData.from_first_name,
        from_last_name: formData.from_last_name,
        company: formData.company,
        phone: formData.phone,
        message: formData.message
      },
      'Rq9lhrfpsiXg0eDbt'        // Public Key (User ID)
    )
    .then(() => {
      setStatus('Message sent successfully!');
      setFormData({ from_first_name: '', from_last_name: '', company: '', phone: '', email: '', message: '' });
    })
    .catch(() => {
      setStatus('Failed to send message. Please try again.');
    });
  };

  return (
    <Box sx={{ position: "relative", width: "100vw", minHeight: "100vh" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: -1
        }}
      />

      <Box className="section" sx={{ position: "relative", zIndex: 1, display: 'flex', justifyContent: 'center' }}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingY: "2rem",
            paddingX: { xs: "5%", md: "2%" },
            textAlign: "center",
            width: { xs: "95vw", md: "60vw" },
            color: '#FFFFFF',
            boxShadow: "none",
          }}
          color="primary"
        >
          <Typography color="secondary" variant="h4" sx={{ marginTop: 0, paddingTop: 0 }}>
            Interested in discussing a project with me?
          </Typography>
          <Divider sx={{ paddingTop: "1rem", width: { xs: "80%", md: "50rem" } }} />
          <Typography color="secondary" variant="body1" sx={{ paddingTop: "1rem" }}>
            Let’s connect! Reach out if you'd like to discuss opportunities or potential collaborations.
            <br />
            Alternatively, reach out to me on <a href="tel: +447983808138" style={{ color: "white" }}><b>+44 7983808138</b></a> or email me at <a href="mailto: joeldanielz@icloud.com" style={{ color: "white" }}><b>joeldanielz@icloud.com</b></a>
          </Typography>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: 5,
              gap: 2,
            }}
            onSubmit={sendEmail}
          >
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, width: "100%" }}>
              <TextField
                label="First name"
                variant="filled"
                color="secondary"
                name="from_first_name"
                value={formData.from_first_name}
                onChange={handleInputChange}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.15)',
                  width: "100%",
                  color: "#FFFFFF",
                }}
              />
              <TextField
                label="Last name"
                variant="filled"
                color="secondary"
                name="from_last_name"
                value={formData.from_last_name}
                onChange={handleInputChange}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.15)',
                  width: "100%",
                  color: "#FFFFFF",
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, width: "100%" }}>
              <TextField
                label="Company"
                variant="filled"
                color="secondary"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.15)',
                  width: "100%",
                  color: "#FFFFFF",
                }}
              />
              <TextField
                label="Phone Number"
                variant="filled"
                color="secondary"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.15)',
                  width: "100%",
                  color: "#FFFFFF",
                }}
              />
            </Box>
            <TextField
              label="Email"
              variant="filled"
              color="secondary"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                width: "100%",
                color: "#FFFFFF",
              }}
            />
            <TextField
              label="Message"
              multiline
              rows={4}
              variant="filled"
              color="secondary"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                width: "100%",
                color: "#FFFFFF",
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 3 }}>
              <Button variant="contained" color="secondary" onClick={sendEmail}>
                Submit
              </Button>
            </Box>
            <Typography variant="body2" color="secondary" sx={{ marginTop: 2 }}>
              {status}
            </Typography>
          </FormControl>
        </Card>
      </Box>
    </Box>
  );
};

export default ContactUs;
