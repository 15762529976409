// src/pages/Experience.js

import { Box, Typography, Card, Divider } from "@mui/material";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../styles/Experience.css';
import PizzaHutLogo from '../assets/PizzaHutLogo.png';
import Frankie from '../assets/FrankLogo.png';
import BellaItalia from '../assets/BellaItaliaLogo.svg.png';
import Iguanas from '../assets/IguanasLogo.png';
import CafeRouge from '../assets/CafeRougeLogo.svg.png';
import Cote from '../assets/CoteLogo.png';
import Credence from '../assets/CredenceLogo.png';
import Zioxi from '../assets/zioxiLogo.png';
import threerocks from '../assets/threerocksLogo.png';
import bg from '../assets/codingBackground.jpg';
import happen from '../assets/happen-logo.png'
import pulseguard from '../assets/pulseguard.jpg'

const Experience = () => (
    <Box sx={{ position: "relative", width: "100vw", minHeight: "100vh" }}>
        {/* Background Layer */}
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                zIndex: -1
            }}
        />

        {/* Scrollable Content */}
        <Box
            sx={{
                position: "relative",
                zIndex: 1,
                paddingBottom: "2rem",
                paddingTop: "2rem"
            }}
        >
            <Box className="section">
                <ExperienceCard />
            </Box>
        </Box>
    </Box>
);

const ExperienceCard = () => (
    <Card
        sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingX: "1%",
            paddingY: "2%",
            textAlign: "center",
            width: { xs: "95vw", md: "60vw" }
        }}
        color="primary"
    >
        <Typography color="secondary" variant="h4">
            Experience
        </Typography>
        <Divider sx={{ paddingTop: "1rem", width: "50rem" }} />
        <ExperienceDescription />
        <ClientCarousel />
        <AdditionalExperience />
    </Card>
);

const ExperienceDescription = () => (
    <Typography color="secondary" variant="body2" sx={{
        paddingX: "15%",
        paddingTop: "1rem"
    }}>
        I'm a software engineer with over <b>seven years</b> of commercial experience, though my journey in programming began even earlier.
        I'm deeply passionate about coding and take great pride in delivering high-quality work.
        My primary focus has been on <b>.NET</b >, <b>React</b>, and < b > SQL</b > throughout my career, though I’ve gained experience with a variety of other technologies.
        For a full overview, please visit < a href="/skills" > My Skills</a >.
    </Typography >
);

const ClientCarousel = () => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "50rem",
            marginY: "5rem"
        }}
    >
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={{
                default: {
                    breakpoint: { max: 3000, min: 0 },
                    items: 5,
                    slidesToSlide: 3
                }
            }}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={10000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={"default"}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            <img style={{ maxHeight: "100px", alignItems: "center" }} src={PizzaHutLogo} alt="Pizza Hut" />
            <img style={{ maxHeight: "100px" }} src={Frankie} alt="Frankie" />
            <img style={{ maxHeight: "50px" }} src={BellaItalia} alt="Bella Italia" />
            <img style={{ maxHeight: "100px" }} src={Iguanas} alt="Iguanas" />
            <img style={{ maxHeight: "70px" }} src={CafeRouge} alt="Cafe Rouge" />
            <img style={{ maxHeight: "100px" }} src={Cote} alt="Cote" />
            <img style={{ maxHeight: "40px", alignItems: "center" }} src={Credence} alt="Credence" />
            <img style={{ maxHeight: "100px" }} src={Zioxi} alt="Zioxi" />
        </Carousel>
    </Box>
);

const AdditionalExperience = () => {
    const experiences = [
        {
            logo: threerocks,
            role: "Full Stack Developer",
            company: "Threerocks (formerly Adactus)",
            dates: "March 2021 – Present",
            details: [
                "Spearheaded multiple high-impact projects, from inception through deployment, within both small and large-scale environments. Delivered robust solutions across all phases of the development lifecycle, consistently meeting deadlines and client requirements.",
                "Designed and built secure APIs, web services, and microservices architectures using C# and .NET (versions 8, 7, and 6), integrating seamlessly with various third-party APIs to enhance application capabilities.",
                "Led a team in the development of web applications using modern JavaScript frameworks (React and Nuxt), implementing responsive front-end designs with Tailwind CSS and Material, ensuring a user-friendly and efficient experience.",
                "Utilized Azure DevOps and GitHub Actions for CI/CD, orchestrating deployment pipelines to streamline processes and improve project turnaround times. Managed project repositories and conducted code reviews using Git and GitHub.",
                "Established and maintained secure SQL databases using Entity Framework and Dapper, focusing on performance optimization, data integrity, and security in high-stakes applications.",
                "Acted as a mentor to junior developers, providing guidance on best practices, debugging, and optimizing code for scalability and security."
            ]
        },
        {
            logo: happen,
            role: "Full Stack Developer",
            company: "Happen Web Technologies",
            dates: "May 2019 – March 2021",
            details: [
                "Developed and maintained cloud-enabled applications, including mobile and progressive web applications (PWAs), with a strong focus on Entity Framework Core for data persistence and .NET Core for backend services.",
                "Engineered RESTful APIs and integrated them with client-facing applications, ensuring high responsiveness and reliability. Leveraged Blazor and modern JavaScript frameworks to enhance frontend functionality where needed.",
                "Participated in domain-driven design for complex systems, aligning software architecture with client needs and enhancing maintainability across distributed teams.",
                "Collaborated closely with cross-functional teams, translating business requirements into technical specifications and managing project tasks through GitHub Projects."
            ]
        },
        {
            logo: pulseguard,
            role: "Junior Software Developer",
            company: "Pulseguard",
            dates: "September 2017 – May 2019",
            details: [
                "Played a key role in the development of APIs for a medical seizure monitoring system, contributing to a mission-critical, life-saving application using C#, .NET MVC, and MySQL.",
                "Worked across the stack, from backend data handling to frontend user interface, delivering a seamless experience that met strict performance and reliability standards.",
                "Supported development of in-house tools and systems, including high-security web services, reinforcing data protection and compliance requirements."
            ]
        }
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {experiences.map((exp, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        alignItems: { xs: 'center', md: 'flex-start' }, // Center on mobile, left-align on desktop
                        flexDirection: { xs: 'column', md: 'row' },
                        maxWidth: { xs: '90%', md: '100%' },
                        marginBottom: '2.5rem',
                        padding: '1.5rem',
                        paddingX: { xs: '1rem', md: '2rem' },
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: '8px'
                    }}
                >
                    {/* Logo Container with Responsive Width and Centering on Mobile */}
                    <Box
                        sx={{
                            width: { xs: '200px', md: '400px' }, // 200px on mobile, 400px on desktop
                            display: 'flex',
                            justifyContent: { xs: 'center', md: 'flex-start' }, // Centered on mobile, left-aligned on desktop
                            alignItems: 'center',
                            marginRight: { xs: 0, md: '1.5rem' },
                            marginBottom: { xs: '1rem', md: 0 }
                        }}
                    >
                        {exp.logo && (
                            <Box
                                component="img"
                                src={exp.logo}
                                alt="Experience logo"
                                sx={{
                                    maxHeight: '80px', // Maintain original aspect ratio and size
                                    maxWidth: '100%'
                                }}
                            />
                        )}
                    </Box>

                    {/* Text Content */}
                    <Box sx={{ textAlign: { xs: 'center', md: 'left' }, width: '100%' }}>
                        {exp.role && (
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                                {exp.role}
                            </Typography>
                        )}
                        {exp.company && (
                            <Typography variant="subtitle1" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                                {exp.company}
                            </Typography>
                        )}
                        {exp.dates && (
                            <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom: '1rem' }}>
                                {exp.dates}
                            </Typography>
                        )}
                        {exp.details && (
                            <Box component="ul" sx={{ paddingLeft: '1.25rem', listStyleType: 'disc', color: 'text.primary' }}>
                                {exp.details.map((detail, i) => (
                                    <Typography key={i} component="li" variant="body2" sx={{ marginBottom: '0.5rem' }}>
                                        {detail}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default Experience;
