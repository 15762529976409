import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import Particles from "react-tsparticles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import "../styles/Slogans.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import XIcon from '@mui/icons-material/X';

const FADE_INTERVAL_MS = 3000;
const WORD_CHANGE_INTERVAL_MS = FADE_INTERVAL_MS * 2;
const WORDS_TO_ANIMATE = [
  '"Talk is cheap. Show me the code." - Linus Torvalds',
  '"Programs must be written for people to read, and only incidentally for machines to execute." - Harold Abelson',
  '"Any fool can write code that a computer can understand. Good programmers write code that humans can understand." - Martin Fowler',
  '"First, solve the problem. Then, write the code." - John Johnson',
  '"Java is to JavaScript what car is to Carpet." - Chris Heilmann',
  '"Sometimes it pays to stay in bed on Monday, rather than spending the rest of the week debugging Monday’s code." - Dan Salomon',
  '"Simplicity is the soul of efficiency." - Austin Freeman',
  '"Code is like humor. When you have to explain it, it’s bad." - Cory House'
];

const Slogans = () => {
  const [fadeProp, setFadeProp] = useState<string>("fade-in");
  const [wordOrder, setWordOrder] = useState(0);

  const particlesInit = useCallback(async (engine: Engine) => {
    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
      await console.log(container);
    },
    []
  );

  useEffect(() => {
    const fadeTimeout = setInterval(() => {
      fadeProp === "fade-in" ? setFadeProp("fade-out") : setFadeProp("fade-in");
    }, FADE_INTERVAL_MS);

    return () => clearInterval(fadeTimeout);
  }, [fadeProp]);

  useEffect(() => {
    const wordTimeout = setInterval(() => {
      setWordOrder(
        (prevWordOrder) => (prevWordOrder + 1) % WORDS_TO_ANIMATE.length
      );
    }, WORD_CHANGE_INTERVAL_MS);

    return () => clearInterval(wordTimeout);
  }, []);

  return (
    <Box
      sx={{
        minHeight: "18rem",
        marginX: "15%",
        textAlign: "center",
      }}
    >
      {/* <IconButton href="https://instagram.com/RaphleOfficial" target="_blank">
        <InstagramIcon className="social-media-button"></InstagramIcon>
      </IconButton>
      <IconButton href="https://twitter.com/RaphleOfficial" target="_blank">
        <XIcon className="social-media-button"></XIcon>
      </IconButton> */}
      <IconButton href="https://www.linkedin.com/in/joel-danielz-106275182/" target="_blank">
        <LinkedInIcon className="social-media-button"></LinkedInIcon>
      </IconButton>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: {
            enable: true,
            zIndex: -1,
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 1,
              },
              repulse: {
                distance: 100,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#845ed1",
            },
            links: {
              color: "#bdaefe",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 55,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 1 },
            },
          },
          retina_detect: true,
          background: {
            color: "#000000",
            image: "",
            position: "50% 50%",
            repeat: "no-repeat",
            size: "cover",
          },
        }}
      />
      <Typography
        className={fadeProp}
        variant={"h3"}
        color={"#9a9a9a"}
        sx={{ marginTop: "3%" }}
      >
        {WORDS_TO_ANIMATE[wordOrder]}
      </Typography>
    </Box>
  );
};

export default Slogans;
