import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = (dark: boolean) =>
  createTheme({
    palette: {
      ...(dark
        ? {
            mode: "dark",
            primary: {
              main: "#000000",
            },
            secondary: {
              dark: "blue",
              light: "red",
              main: "#FFFFFF",
            },
            error: {
              main: red.A400,
            },
            action: {
              active: "#FFFFFF",
            },
          }
        : {
            mode: "light",
            primary: {
              main: "#FFFFFF",
            },
            secondary: {
              dark: "blue",
              light: "red",
              main: "#FFFFFF",
            },
            error: {
              main: red.A400,
            },
            action: {
              active: "#FFFFFF",
            },
          }),
    },
    typography: {
      h2: {
        fontWeight: 450,
      },
      h3:{
        fontFamily: "Press Start 2P",
        fontSize: "2rem"
      },
      body1:{
        fontFamily: "Source Code Pro",
        fontOpticalSizing: "auto",
      }
    },
  });

export default theme;
