import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box, useTheme } from "@mui/material";
import { url } from "inspector";

interface RoutingProps {
  children: React.ReactNode;
}

const Routing = (props: RoutingProps) => {
  const theme = useTheme();
  const variants = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <div>
      <AnimatePresence>
        <motion.div
          initial={false}
          variants={variants}
          transition={{
            duration: 0.1,
            delay: 0.1,
          }}
        >
          <Box
            sx={{
              minHeight: "93vh",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {props.children}
          </Box>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Routing;
