import React, { useState } from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import NavBarMenu from "./components/NavBarMenu";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { Box } from "@mui/material";
import Routing from "./components/layout/Routing";
import Pricing from "./pages/Pricing";
import SplashScreen from "./components/SplashScreen";
import ContactUs from "./pages/ContactUs";
import Experience from "./pages/Experience";
import MySkills from "./pages/MySkills";
import PersonalProfile from "./pages/PersonalProfile";
import ScrollToTop from "./pages/ScrollToTop";

function App() {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);
  const [showSplash, setShowSplash] = useState(
    localStorage.getItem("splashShown") !== "true"
  );

  return (
    <Box>
      {showSplash ? (
        <Box>
          <SplashScreen setSplashScreenShown={setShowSplash} />
        </Box>
      ) : (
        <Box>
          <NavBarMenu
            setIsHamburgerOpen={setIsHamburgerOpen}
            isHamburgerOpen={isHamburgerOpen}
          />

          <NavBar
            setIsHamburgerOpen={setIsHamburgerOpen}
          />

          <Routing>
            <ScrollToTop/>
            {/* Default path */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/experience" element={<Experience />} />
              <Route path="/skills" element={<MySkills />} />
              <Route path="/profile" element={<PersonalProfile />} />
            </Routes>
          </Routing>
        </Box>
      )}
    </Box>
  );
}

export default App;
